import { Theme } from "@mui/material";
import { ReactNode, ReactNodeArray, useState } from "react";
import { WerewolfThemeContext } from "./WerewolfThemeContext";

interface WerewolfThemeProviderProps {
    value: Theme
    children: ReactNode | ReactNodeArray
}

export default function WerewolfThemeProvider(props: WerewolfThemeProviderProps) {
    const { value, children } = props;

    const [theme, setTheme] = useState(value);

    return <WerewolfThemeContext.Provider value={{ theme, setTheme }}>
        {children}
    </WerewolfThemeContext.Provider>
}