import { useCallback, useEffect } from "react";
import useMutateSessionToken from "../hooks/sessiontoken/useMutateSessionToken";
import useSessionToken from "../hooks/sessiontoken/useSessionToken";
import usePlayer from "../swr/usePlayer";
import api from "../utils/api";

export default function SessionInitializer() {
    const sessionToken = useSessionToken();
    const mutateSessionToken = useMutateSessionToken();

    const { error, mutate } = usePlayer(sessionToken);

    const updateSessionToken = useCallback((sessionToken: string) => {
        window.localStorage.setItem("sessionToken", sessionToken);
        mutateSessionToken(sessionToken);
    }, [mutateSessionToken]);

    const initializeSession = useCallback(async () => {
        try {
            const result = await api.post("/session", { sessionToken: null }, { responseType: "text", withCredentials: true });
            updateSessionToken(result.data);
        } catch (err) {
            console.error(err);
        }
    }, [updateSessionToken]);

    useEffect(() => {
        if (error) {
            if (error.status && (error.status === 404 || error.status === 403) && error.info.provided === sessionToken) {
                (async () => {
                    await initializeSession();
                    mutate();
                })();
            }
        }
    }, [error, initializeSession, sessionToken, mutate]);

    useEffect(() => {
        if (window.localStorage && sessionToken === undefined) {
            initializeSession();
        }
    }, [sessionToken, initializeSession])

    useEffect(() => {
        if (sessionToken) {
            api.defaults.headers.common['Authorization'] = "Bearer " + sessionToken;
        }
    }, [sessionToken]);

    return null;
}