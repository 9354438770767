import { EventEmitter } from "events";
import { ReactNode, ReactNodeArray, useCallback, useEffect, useState } from "react";
import { Socket } from "socket.io-client";
import useSessionToken from "../sessiontoken/useSessionToken";
import { ChatConnectionContext } from "./ChatConnectionContext";

interface ChatConnectionProviderProps {
    value?: Socket
    children: ReactNode | ReactNodeArray
}

export default function ChatConnectionProvider(props: ChatConnectionProviderProps) {
    const { value, children } = props;

    const [chatId, setChatId] = useState<string | null>(null);
    const [joinedPhases, setJoinedPhases] = useState<string[]>([]);
    const [connection, setConnection] = useState(value);
    const [connecting, setConnecting] = useState(false);

    const sessionToken = useSessionToken();

    const connect = useCallback(async (chatId: string) => {
        setChatId(chatId);
        if (!connection) {
            setConnecting(true);
            const io = (await import('socket.io-client')).io;
            setConnection(io(`${process.env.apiUrl}/chat`, {
                path: "/socket",
                transports: ['websocket', 'polling']
            }));
        }
    }, [connection, setConnection, setConnecting, setChatId]);

    const joinPhase = useCallback((phaseId: string) => {
        if (connection) {
            connection.emit("joinPhase", chatId, phaseId, (res) => {
                if (res.joined) {
                    setJoinedPhases(p => [...p, phaseId]);
                } else {
                    console.error("Could not join chat phase", res.error);
                }
            });
        }
    }, [connection, chatId, setJoinedPhases]);

    const leavePhase = useCallback((phaseId: string) => {
        if (connection) {
            connection.emit("leavePhase", chatId, phaseId, (res) => {
                if (res.left) {
                    setJoinedPhases(j => j.filter(p => p !== phaseId));
                } else {
                    console.error("Could not leave chat phase", res.error);
                }
            });
        }
    }, [connection, chatId, setJoinedPhases]);

    const leaveAllPhases = useCallback(() => {
        joinedPhases.forEach(leavePhase);
    }, [joinedPhases, leavePhase]);

    const disconnect = useCallback(() => {
        if (connection) {
            connection.disconnect();
            setConnection(undefined);
            setChatId(null);
        }
        setConnecting(false);
    }, [setConnecting, setConnection, setChatId, connection]);

    useEffect(() => {
        if (connection) {
            let mounted = true;

            const onConnect = () => {
                // Disable re-connection to prevent infinite reconnection tries
                connection.off("connect", onConnect);
                connection.emit("authenticate", sessionToken, (res) => {
                    if (mounted) {
                        if (!res.connected) {
                            console.error("Could not connect to chat", res.error);
                        }
                        setConnecting(false);
                    }
                });
            }

            if (connection.connected && connecting) {
                onConnect();
            }

            connection.on("connect", onConnect);

            return function cleanup() {
                connection.off("connect", onConnect);
                mounted = false;
            }
        }
    }, [connection, setConnecting, connecting, sessionToken]);

    useEffect(() => {
        if (connection) {
            return function cleanup() {
                connection.disconnect();
            }
        }
    }, [connection]);

    return <ChatConnectionContext.Provider value={{ connection, connecting, chatId, connect, joinedPhases, joinPhase, leavePhase, leaveAllPhases, disconnect }}>
        {children}
    </ChatConnectionContext.Provider>
}