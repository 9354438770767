import { Theme } from "@mui/material";
import { createContext } from "react";
import { themes } from "./Themes";

type WerewolfTheme = {
    theme: Theme,
    setTheme: (theme: Theme) => void
}

export const WerewolfThemeContext = createContext<WerewolfTheme>({
    theme: themes.light,
    setTheme: () => {}
});