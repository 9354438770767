const disjunctionList = {
    en: new Intl.ListFormat("en-GB", { style: "long", type: "disjunction" }),
    de: new Intl.ListFormat("de", { style: "long", type: "disjunction" }),
}

const date = {
    en: new Intl.DateTimeFormat("en"),
    de: new Intl.DateTimeFormat("de"),
}

const dateTime = {
    en: new Intl.DateTimeFormat("en", { dateStyle: "short", timeStyle: "short" }),
    de: new Intl.DateTimeFormat("de", { dateStyle: "short", timeStyle: "short" }),
}

const dateTimeLong = {
    en: new Intl.DateTimeFormat("en", { dateStyle: "short", timeStyle: "medium" }),
    de: new Intl.DateTimeFormat("de", { dateStyle: "short", timeStyle: "medium" }),
}

module.exports = {
    locales: ["en", "de"/*, "tr"*/],
    defaultLocale: "en",
    pages: {
        "*": ["common", "social", "general"],
        "/": ["landing", "settings", "header", "footer", "changelog", "languages", "faq", "roles"],
        "/changelogs": ["header", "footer", "changelog"],
        "/vote": ["header", "footer", "roles", "rolevoting"],
        "/vote/create": ["roles"],
        "rgx:/changelog(/.*)?$": ["header", "footer", "changelog"],
        "rgx:/room/.*$": ["header", "footer", "landing", "settings", "changelog", "languages", "room", "roles", "faq", "gamecommons"],
        "rgx:/game/.*$": ["header", "footer", "languages", "roles", "game", "tips", "gamecommons"],
        "rgx:/preview(/.*)?$": ["header", "footer", "landing", "settings", "changelog", "languages", "room", "roles", "faq", "game", "gamecommons"]
    },
    interpolation: {
        prefix: "{{",
        suffix: "}}",
        format: (value, format, lang) => {
            if (format === 'disjunctionList') return disjunctionList[lang].format(value);
            if (format === 'dateTime') return dateTime[lang].format(value);
            if (format === 'dateTimeLong') return dateTimeLong[lang].format(value);
            if (format === 'date') return date[lang].format(value);
            return value
        }
    },
    loadLocaleFrom: (lang, ns) =>
        import(`./locales/${lang}/${ns}.json`).then((m) => m.default),
}