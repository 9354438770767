import Head from "next/head";
import { useRouter } from "next/router";

const seoTitle = "Play Werewolf for Free! – Werewolf Online | NextGlobe"
const title = "Werewolf Online | NextGlobe"
const slogan = "Werewolf Online is a free social deduction game similar to Mafia that can be played with up to 50 friends from all over the world."
const url = "https://werewolf-online.io"

export default function DefaultHead() {
    const router = useRouter();

    // eslint-disable-next-line no-undef
    const baseUrl = (process.browser && (window.location.protocol + "//" + window.location.host)) || url;

    return <Head>
        <link rel="icon" href="/favicon.ico" />
        <meta name="theme-color" content="#00254C" />
        <title>{seoTitle}</title>
        <meta name="description" content={slogan} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@NextGlobeNet" />
        <meta name="twitter:image" content={baseUrl + "/og/en.png"} />
        <meta name="og:title" content={title} />
        <meta name="og:site_name" content={title} />
        <meta name="og:url" content={url} />
        <meta name="og:type" content="website" />
        <meta name="og:description" content={slogan} />
        <meta name="og:image" content={baseUrl + "/og/en.png"} />
        <link rel="manifest" href="/manifest.json" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />

        {["de", "tr"].map(language => {
            return <link key={language} rel="alternate" hrefLang={language} href={baseUrl + "/" + language + router.asPath} />
        })}
        <link rel="alternate" hrefLang="en" href={baseUrl + router.asPath} />
    </Head>;
}
