import { ReactNode, ReactNodeArray, useEffect, useState } from "react";
import useChatData from "../chatdata/useChatData";
import { FixedChatStateContext } from "./FixedChatStateContext";

interface FixedChatStateProviderProps {
    value?: boolean
    children: ReactNode | ReactNodeArray
}

export default function FixedChatStateProvider(props: FixedChatStateProviderProps) {
    const { value, children } = props;

    const [open, setOpen] = useState(value);

    const chatData = useChatData();

    useEffect(() => {
        if (chatData?.id) {
            setOpen(false);
        }
    }, [chatData?.id, setOpen]);

    return <FixedChatStateContext.Provider value={{ open, setOpen }}>
        {children}
    </FixedChatStateContext.Provider>
}