import useSWR, { KeyedMutator } from "swr";
import { SelfPlayer } from "../types/SelfPlayer";
import fetcher from "../utils/fetcher";

export default function usePlayer(sessionToken: string): { player: SelfPlayer, loading: boolean, error: any, mutate: KeyedMutator<any> } {
    const { data, error, mutate } = useSWR(sessionToken && [(process.env.apiUrl + '/player'), sessionToken], (url, token) => fetcher(url, {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }), { shouldRetryOnError: false });

    return {
        player: data,
        loading: !error && !data,
        error,
        mutate
    }
}