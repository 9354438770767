import useSWR from "swr";
import fetcher from "../utils/fetcher";

export default function useRoomPlayers(sessionToken, roomId, fallbackData) {
    const { data, error, mutate } = useSWR(sessionToken && roomId && [(process.env.apiUrl + '/room/'), sessionToken, roomId, "/players"], (url, token, roomId, endpoint) => fetcher(url + roomId + endpoint, {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }), { fallbackData });

    return {
        players: data,
        loading: !error && !data,
        error,
        mutate
    }
}