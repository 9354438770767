import { ReactNode, ReactNodeArray, useEffect, useState } from "react";
import useChatData from "../chatdata/useChatData";
import { ChatInputContext } from "./ChatInputContext";

interface ChatInputProviderProps {
    value?: string
    children: ReactNode | ReactNodeArray
}

export default function ChatInputProvider(props: ChatInputProviderProps) {
    const { value, children } = props;

    const [data, setData] = useState(value ?? "");

    const chatData = useChatData();

    useEffect(() => {
        if (chatData?.id) {
            setData("");
        }
    }, [chatData?.id, setData]);

    return <ChatInputContext.Provider value={{ input: data, setInput: setData }}>
        {children}
    </ChatInputContext.Provider>
}