import axios from "axios";
import axiosRetry from "axios-retry";

const api = axios.create({
    baseURL: process.env.apiUrl,
    headers: {
        post: {
            'Content-Type': 'application/json'
        },
        patch: {
            'Content-Type': 'application/json'
        },
        put: {
            'Content-Type': 'application/json'
        }
    }
});

axiosRetry(api, { retryDelay: axiosRetry.exponentialDelay});

export default api;