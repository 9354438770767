import { ReactNode, ReactNodeArray, useCallback, useEffect, useState } from "react";
import { Chat } from "../../types/chat/Chat";
import { ChatMessage } from "../../types/chat/message/ChatMessage";
import api from "../../utils/api";
import useChatConnection from "../chatconnection/useChatConnection";
import { ChatDataContext } from "./ChatDataContext";
import { ChatPhaseData } from "../../types/chat/phases/ChatPhase";
import update from "immutability-helper";

interface ChatDataProviderProps {
    value?: Chat
    children: ReactNode | ReactNodeArray
}

export default function ChatDataProvider(props: ChatDataProviderProps) {
    const { value, children } = props;

    const [data, setData] = useState(value);

    const chat = useChatConnection();

    //#region Initial chat data loading
    // Initial chat data loads as soon as the chat id is available/changes
    useEffect(() => {
        if (chat.chatId) {
            let mounted = true;

            (async () => {
                const chatData = await api.get(`/chat/${chat.chatId}`);
                if (mounted) {
                    setData(chatData.data as Chat);
                }
            })();

            return () => { mounted = false }
        } else {
            setData(undefined);
        }
    }, [chat?.chatId, setData]);
    //#endregion

    //#region Chat message handler
    const onChatMessage = useCallback((data: { chatId: string, phaseId: string, message: ChatMessage }) => {
        setData(chatData => {
            const index = chatData.phases.findIndex(phase => phase.id === data.phaseId);
            if (index !== -1) {
                return update(chatData, { phases: { [index]: { messages: { $push: [data.message] } } } });
            } else {
                console.warn(`[Unknown chat phase] Received a message in phase ${data.phaseId}. This phase was not registered. Ignoring message`);
            }
            return chatData;
        });
    }, [setData]);

    useEffect(() => {
        if (chat.connection) {
            chat.connection.on("message", onChatMessage);

            return () => {
                chat.connection.off("message", onChatMessage);
            }
        }
    }, [chat, onChatMessage]);
    //#endregion

    //#region New phase handler
    const onNewPhase = useCallback((data: { chatId: string, phase: ChatPhaseData }) => {
        setData(chatData => {
            const index = chatData.phases.findIndex(phase => phase.id === data.phase.id);
            if (index === -1) {
                return update(chatData, { phases: { $push: [data.phase] } });
            } else {
                console.warn(`[Chat phase already registered] A new phase was registered, but phase ${data.phase.id} is already registered. Ignoring it`);
            }
            return chatData;
        });
        chat.leaveAllPhases();
        chat.joinPhase(data.phase.id);
    }, [chat, setData]);

    useEffect(() => {
        if (chat.connection) {
            chat.connection.on("new-phase", onNewPhase);

            return () => {
                chat.connection.off("new-phase", onNewPhase);
            }
        }
    }, [chat, onNewPhase]);
    //#endregion

    return <ChatDataContext.Provider value={data}>
        {children}
    </ChatDataContext.Provider>
}