import { createContext } from "react";

export interface LastRead {
    chatId: string,
    phase: string,
    phaseIndex: number,
    messageId: string | null,
    messageIndex: number,
}

export interface UnreadMessages extends LastRead {
    unreadMessagesCount: number,
    updateLastRead: () => void
}

export const UnreadMessagesContext = createContext<UnreadMessages>(null);