import { ReactNode, ReactNodeArray, useCallback, useEffect, useMemo, useState } from "react";
import useChatData from "../chatdata/useChatData";
import { LastRead, UnreadMessages, UnreadMessagesContext } from "./UnreadMessagesContext";

interface UnreadMessagesProviderProps {
    children: ReactNode | ReactNodeArray
}

export default function UnreadMessagesProvider(props: UnreadMessagesProviderProps) {
    const { children } = props;

    const chatData = useChatData();

    const [lastRead, setLastRead] = useState<LastRead>(undefined);

    const updateLastRead = useCallback(() => {
        if (chatData) {
            const phaseIndex = chatData.phases.length - 1;
            const phase = chatData.phases[phaseIndex];
            const messageIndex = phase.messages.length - 1;
            setLastRead({
                chatId: chatData.id,
                phase: phase.id,
                phaseIndex: phaseIndex,
                messageId: phase.messages[messageIndex]?.id ?? null,
                messageIndex: messageIndex
            });
        }
    }, [chatData, setLastRead]);

    useEffect(() => {
        if (lastRead?.chatId !== chatData?.id) {
            updateLastRead();
        }
    }, [lastRead?.chatId, chatData?.id, updateLastRead]);

    const unreadMessagesCount = useMemo(() => {
        if (lastRead !== undefined && chatData) {
            let unread = chatData.phases[lastRead.phaseIndex].messages.length - (lastRead.messageIndex === -1 ? 0 : lastRead.messageIndex + 1);
            for (let index = lastRead.phaseIndex + 1; index < chatData.phases.length; index++) {
                const phase = chatData.phases[index];
                unread += phase.messages.length;
            }
            return unread;
        } else {
            return 0;
        }
    }, [lastRead, chatData]);

    return <UnreadMessagesContext.Provider value={{ ...lastRead, unreadMessagesCount, updateLastRead }}>
        {children}
    </UnreadMessagesContext.Provider>
}