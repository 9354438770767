import { ReactNode, ReactNodeArray, useCallback, useEffect, useState } from "react";
import { Socket } from "socket.io-client";
import useRoomAutoKick from "../../swr/useRoomAutoKick";
import useRoomOwner from "../../swr/useRoomOwner";
import useRoomPlayers from "../../swr/useRoomPlayers";
import useSessionToken from "../sessiontoken/useSessionToken";
import { RoomConnectionContext } from "./RoomConnectionContext";

interface RoomConnectionProviderProps {
    value?: Socket
    children: ReactNode | ReactNodeArray
}

export default function RoomConnectionProvider(props: RoomConnectionProviderProps) {
    const { value, children } = props;

    const [roomId, setRoomId] = useState<string | null>(null);
    const [connection, setConnection] = useState(value);
    const [connecting, setConnecting] = useState(false);
    const [connectionError, setConnectionError] = useState<string>(undefined);

    const sessionToken = useSessionToken();

    const connect = useCallback(async (roomId: string) => {
        setConnecting(true);
        setRoomId(roomId);
        const io = (await import('socket.io-client')).io;
        setConnection(io(`${process.env.apiUrl}/room/${roomId}`, {
            path: "/socket",
            transports: ['websocket', 'polling']
        }));
    }, [setConnection, setConnecting, setRoomId]);

    const clearError = useCallback(() => {
        setConnectionError(undefined);
    }, [setConnectionError]);

    const disconnect = useCallback(() => {
        if (connection) {
            connection.disconnect();
            setConnection(undefined);
            setRoomId(null);
        }
        setConnecting(false);
    }, [setConnecting, setConnection, setRoomId, connection]);

    useEffect(() => {
        if (connection) {
            let mounted = true;

            const onConnect = () => {
                // // Disable re-connection to prevent infinite reconnection tries
                // connection.off("connect", onConnect);
                connection.emit("authenticate", sessionToken, (res) => {
                    if (mounted) {
                        if (!res.connected) {
                            console.error("Could not connect to room", res.error);
                            setConnectionError(res.error);
                        }
                        setConnecting(false);
                    }
                });
            }

            connection.on("connect", onConnect);

            if (connection.connected && connecting) {
                onConnect();
            }

            return function cleanup() {
                connection.off("connect", onConnect);
                mounted = false;
            }
        }
    }, [connection, setConnecting, connecting, sessionToken, setConnectionError]);

    //#region room players handler
    const { players, mutate: mutatePlayers } = useRoomPlayers(sessionToken, roomId);
    const { ownerData, mutate: mutateOwnerData } = useRoomOwner(sessionToken, roomId);
    const { autoKickData, mutate: mutateAutoKickData } = useRoomAutoKick(sessionToken, roomId);

    useEffect(() => {
        if (connection) {
            const onPlayerJoined = (playerId: string) => {
                if (players) {
                    mutatePlayers({ players: [...players.players.filter(p => p !== playerId), playerId] });
                } else {
                    mutatePlayers({ players: [playerId] });
                }
            }

            connection.on("playerJoined", onPlayerJoined);

            return function cleanup() {
                connection.off("playerJoined", onPlayerJoined);
            }
        }
    }, [connection, players, mutatePlayers]);

    useEffect(() => {
        if (connection) {
            const onPlayerLeft = (playerId: string) => {
                if (players) {
                    mutatePlayers({ players: players.players.filter(player => player !== playerId) });
                } else {
                    mutatePlayers({ players: [] });
                }
                if (autoKickData?.autoKick?.some(autoKick => autoKick.playerId === playerId)) {
                    mutateAutoKickData({ autoKick: autoKickData.autoKick.filter(autoKick => autoKick.playerId !== playerId) });
                }
                // To be sure that the owner data is correct, we mutate it even if it hasn't been loaded yet by the client
                if (!ownerData || ownerData.owner === playerId) {
                    mutateOwnerData();
                }
            }

            connection.on("playerLeft", onPlayerLeft);

            return function cleanup() {
                connection.off("playerLeft", onPlayerLeft);
            }
        }
    }, [connection, players, ownerData, autoKickData, mutatePlayers, mutateAutoKickData, mutateOwnerData]);

    useEffect(() => {
        if (connection) {
            const onAutoKick = (autoKick) => {
                const data = { playerId: autoKick.playerId, kickAt: Date.now() + autoKick.gracePeriod * 1000 };
                mutateAutoKickData({ autoKick: autoKickData ? [...autoKickData.autoKick, data] : [data], currentTime: Date.now() });
            }

            connection.on("autoKick", onAutoKick);

            return function cleanup() {
                connection.off("autoKick", onAutoKick);
            }
        }
    }, [connection, autoKickData, mutateAutoKickData]);

    useEffect(() => {
        if (connection) {
            const onAutoKickCancelled = (playerId: string) => {
                mutateAutoKickData({ autoKick: autoKickData ? autoKickData.autoKick.filter(autoKick => autoKick.playerId !== playerId) : [] });
            }

            connection.on("autoKickCancelled", onAutoKickCancelled);

            return function cleanup() {
                connection.off("autoKickCancelled", onAutoKickCancelled);
            }
        }
    }, [connection, autoKickData, mutateAutoKickData]);
    //#endregion

    useEffect(() => {
        if (connection) {
            return function cleanup() {
                connection.disconnect();
            }
        }
    }, [connection]);

    return <RoomConnectionContext.Provider value={{ connection, connecting, connect, connectionError, clearError, disconnect }}>
        {children}
    </RoomConnectionContext.Provider>
}