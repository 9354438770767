import { createContext } from "react";
import { Socket } from "socket.io-client";

export type ChatConnection = {
    connection?: Socket;
    connecting: boolean;
    chatId?: string;
    connect: (chatId: string) => Promise<void>;
    joinedPhases: string[];
    joinPhase: (phaseId: string) => void;
    leavePhase: (phaseId: string) => void;
    leaveAllPhases: () => void;
    disconnect: () => void;
}

export const ChatConnectionContext = createContext<ChatConnection>(null);