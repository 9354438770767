import { ThemeProvider } from "@mui/material/styles";
import { ReactNode, ReactNodeArray } from "react";
import useWerewolfTheme from "./useWerewolfTheme";

interface ThemeTranslatorProps {
    children: ReactNode | ReactNodeArray
}

export default function ThemeTranslator(props: ThemeTranslatorProps) {
    const { children } = props;

    const { theme } = useWerewolfTheme();

    return <ThemeProvider theme={theme}>
        {children}
    </ThemeProvider>
}