import { createContext } from "react";
import { Socket } from "socket.io-client";

export type RoomConnection = {
    connection: Socket;
    connecting: boolean;
    connect: (roomId: string) => void;
    connectionError?: string;
    clearError: () => void;
    disconnect: () => void;
}

export const RoomConnectionContext = createContext<RoomConnection>(null);