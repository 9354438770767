import { Router } from "next/router";
import { useEffect, useState } from "react";
import useSessionToken from "../hooks/sessiontoken/useSessionToken";
import usePlayer from "../swr/usePlayer";

const dataLayer = process.browser && (window.dataLayer = window.dataLayer || []);
export function gtag() { dataLayer.push(arguments); }

function anonymizePageProperties() {
    if (process.browser) {
        let location: string, title: string, ref: string;
        if (document.location.pathname.includes("room") || document.location.pathname.includes("game")) {
            location = document.location.toString().substring(0, document.location.toString().lastIndexOf("/"));
            if (document.title) {
                if (document.title.replaceAll) {
                    title = document.title.replaceAll(/:(.*?) –/g, ": <Redacted> –");
                } else {
                    title = "<Redacted>";
                }
            }
        } else {
            location = document.location.toString();
            title = document.title;
        }
        if (document.referrer.includes("room") || document.referrer.includes("game")) {
            ref = document.referrer.substring(0, document.referrer.lastIndexOf("/"));
        } else {
            ref = document.referrer;
        }
        (gtag as any)('set', {
            page_title: title,
            page_location: location,
            page_referrer: ref
        });
        (gtag as any)('config', googleAnalyticsTagId);
    }
}

Router.events.on('routeChangeComplete', anonymizePageProperties);

export const googleAnalyticsTagId = 'G-C039QP8CZN';

export function useGoogleAnalyticsAnonymization() {
    anonymizePageProperties();
}

export function useGoogleAnalyticsAssociateAccountId() {
    const sessionToken = useSessionToken();
    const player = usePlayer(sessionToken);

    const [id, setId] = useState(undefined);

    useEffect(() => {
        if (player?.player) {
            if (player.player.account?.sub) {
                if (crypto && crypto.subtle) {
                    (async () => {
                        const userId = Array.prototype.map.call(new Uint8Array(await crypto.subtle.digest("SHA-256", new TextEncoder().encode(player.player.account.sub))), (x) => ("0" + x.toString(16)).slice(-2)).join("");
                        if (id !== userId) {
                            window.dataLayer.push({
                                "event": "account_data_loaded",
                                "user_id": userId
                            });
                            setId(userId);
                        }
                    })();
                } else {
                    if (id !== null) {
                        window.dataLayer.push({
                            "event": "account_data_loaded"
                        });
                        setId(null);
                    }
                }
            } else {
                if (id !== null) {
                    window.dataLayer.push({
                        "event": "account_data_loaded"
                    });
                    setId(null);
                }
            }
        }
    }, [player?.player, setId, id])
}