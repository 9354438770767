
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import '../styles/globals.css';
import '../styles/nprogress.css';
import { ReactElement, useEffect } from "react";
import { CssBaseline } from '@mui/material';
import DefaultHead from '../components/DefaultHead';
import { Router } from 'next/router';
import NProgress from 'nprogress';
import useMatomo from '../components/Matomo';
import WerewolfThemeProvider from "../theme/WerewolfThemeProvider";
import SessionTokenProvider from "../hooks/sessiontoken/SessionTokenProvider";
import ThemeTranslator from "../theme/ThemeTranslator";
import { themes } from '../theme/Themes';
import { CacheProvider } from '@emotion/react';
import createEmotionCache from "../utils/createEmotionCache";
import RoomConnectionProvider from '../hooks/roomconnection/RoomConnectionProvider';
import SessionInitializer from '../components/SessionInitializer';
import EzoicAdLoader from '../components/EzoicAdLoader';
import Head from "next/head";
import { useGoogleAnalyticsAnonymization, useGoogleAnalyticsAssociateAccountId } from '../components/GoogleAnalytics';
import ChatConnectionProvider from '../hooks/chatconnection/ChatConnectionProvider';
import ChatDataProvider from '../hooks/chatdata/ChatDataProvider';
import Script from 'next/script';
import useMixpanel, { useMixpanelUserIdentityAssociator } from '../utils/Mixpanel';
import ChatInputProvider from '../hooks/chatinput/ChatInputProvider';
import UnreadMessagesProvider from '../hooks/unreadmessages/UnreadMessagesProvider';
import FixedChatStateProvider from '../hooks/fixedchatstate/FixedChatStateProvider';

const tagmanager = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-WCHBC52');`;

const qc = `<!-- InMobi Choice. Consent Manager Tag v3.0 (for TCF 2.2) -->
(function() {
  var host = window.location.hostname;
  var element = document.createElement('script');
  var firstScript = document.getElementsByTagName('script')[0];
  var url = 'https://cmp.inmobi.com'
    .concat('/choice/', '3-1e2xaWp5nGT', '/', host, '/choice.js?tag_version=V3');
  var uspTries = 0;
  var uspTriesLimit = 3;
  element.async = true;
  element.type = 'text/javascript';
  element.src = url;

  firstScript.parentNode.insertBefore(element, firstScript);

  function makeStub() {
    var TCF_LOCATOR_NAME = '__tcfapiLocator';
    var queue = [];
    var win = window;
    var cmpFrame;

    function addFrame() {
      var doc = win.document;
      var otherCMP = !!(win.frames[TCF_LOCATOR_NAME]);

      if (!otherCMP) {
        if (doc.body) {
          var iframe = doc.createElement('iframe');

          iframe.style.cssText = 'display:none';
          iframe.name = TCF_LOCATOR_NAME;
          doc.body.appendChild(iframe);
        } else {
          setTimeout(addFrame, 5);
        }
      }
      return !otherCMP;
    }

    function tcfAPIHandler() {
      var gdprApplies;
      var args = arguments;

      if (!args.length) {
        return queue;
      } else if (args[0] === 'setGdprApplies') {
        if (
          args.length > 3 &&
          args[2] === 2 &&
          typeof args[3] === 'boolean'
        ) {
          gdprApplies = args[3];
          if (typeof args[2] === 'function') {
            args[2]('set', true);
          }
        }
      } else if (args[0] === 'ping') {
        var retr = {
          gdprApplies: gdprApplies,
          cmpLoaded: false,
          cmpStatus: 'stub'
        };

        if (typeof args[2] === 'function') {
          args[2](retr);
        }
      } else {
        if(args[0] === 'init' && typeof args[3] === 'object') {
          args[3] = Object.assign(args[3], { tag_version: 'V3' });
        }
        queue.push(args);
      }
    }

    function postMessageEventHandler(event) {
      var msgIsString = typeof event.data === 'string';
      var json = {};

      try {
        if (msgIsString) {
          json = JSON.parse(event.data);
        } else {
          json = event.data;
        }
      } catch (ignore) {}

      var payload = json.__tcfapiCall;

      if (payload) {
        window.__tcfapi(
          payload.command,
          payload.version,
          function(retValue, success) {
            var returnMsg = {
              __tcfapiReturn: {
                returnValue: retValue,
                success: success,
                callId: payload.callId
              }
            };
            if (msgIsString) {
              returnMsg = JSON.stringify(returnMsg);
            }
            if (event && event.source && event.source.postMessage) {
              event.source.postMessage(returnMsg, '*');
            }
          },
          payload.parameter
        );
      }
    }

    while (win) {
      try {
        if (win.frames[TCF_LOCATOR_NAME]) {
          cmpFrame = win;
          break;
        }
      } catch (ignore) {}

      if (win === window.top) {
        break;
      }
      win = win.parent;
    }
    if (!cmpFrame) {
      addFrame();
      win.__tcfapi = tcfAPIHandler;
      win.addEventListener('message', postMessageEventHandler, false);
    }
  };

  makeStub();

  var uspStubFunction = function() {
    var arg = arguments;
    if (typeof window.__uspapi !== uspStubFunction) {
      setTimeout(function() {
        if (typeof window.__uspapi !== 'undefined') {
          window.__uspapi.apply(window.__uspapi, arg);
        }
      }, 500);
    }
  };

  var checkIfUspIsReady = function() {
    uspTries++;
    if (window.__uspapi === uspStubFunction && uspTries < uspTriesLimit) {
      console.warn('USP is not accessible');
    } else {
      clearInterval(uspInterval);
    }
  };

  if (typeof window.__uspapi === 'undefined') {
    window.__uspapi = uspStubFunction;
    var uspInterval = setInterval(checkIfUspIsReady, 6000);
  }
})();
<!-- End InMobi Choice. Consent Manager Tag v3.0 (for TCF 2.2) -->`

const aip = `var aiptag = aiptag || {};
aiptag.cmd = aiptag.cmd || [];
aiptag.cmd.display = aiptag.cmd.display || [];
aiptag.cmd.player = aiptag.cmd.player || [];`

if (process.browser && window) {
  window['gtag_enable_tcf_support'] = true;
}

Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

const clientSideEmotionCache = createEmotionCache();

const __Page_Next_Translate__ = function App(props) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps, err } = props;

  useGoogleAnalyticsAnonymization();

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  // useMatomo();

  useMixpanel();

  useEffect(() => {
    if (window.__tcfapi !== undefined) {
      window.__tcfapi('addEventListener', 2, function (tcdata, success) {
        if (tcdata.gdprApplies) {
          if (tcdata.eventStatus === "useractioncomplete" || tcdata.eventStatus === "tcloaded") {
            // Disable Matomo if GDPR applies and legitimate interest for "Measure content performance", "Apply market research to generate audience insights" or "Develop and improve products" has been deactivated
            if (!tcdata.publisher.legitimateInterests[10] || !tcdata.publisher.legitimateInterests[9] || !tcdata.publisher.legitimateInterests[8]) {
              (window._paq = window._paq || []).push([function () {
                if (!this.isUserOptedOut()) {
                  (window._paq = window._paq || []).push(['optUserOut']);
                  console.log("Opted out of Matomo")
                }
              }]);
            } else {
              (window._paq = window._paq || []).push([function () {
                if (this.isUserOptedOut()) {
                  (window._paq = window._paq || []).push(['forgetUserOptOut']);
                  console.log("Forgot opt-out of Matomo")
                }
              }]);
            }

            window.__tcfapi("removeEventListener", 2, function (success) {
              return null;
            }, tcdata.listenerId);
          }
        } else {
          window.__tcfapi("removeEventListener", 2, function (success) {
            return null;
          }, tcdata.listenerId);
        }
      });
    }
  }, []);

  return <CacheProvider value={emotionCache}>
    <WerewolfThemeProvider value={process.browser && window.localStorage && window.localStorage.getItem("theme") === "dark" ? themes.dark : themes.light}>
      <SessionTokenProvider value={process.browser && window.localStorage && window.localStorage.getItem("sessionToken") || undefined}>
        <SessionInitializer />
        <Mixpanel>
          <RoomConnectionProvider>
            <ChatConnectionProvider>
              <ChatDataProvider>
                <ChatInputProvider>
                  <FixedChatStateProvider>
                    <UnreadMessagesProvider>
                      <DefaultHead />
                      <Script id="google-tagmanager" strategy="afterInteractive" dangerouslySetInnerHTML={{ __html: tagmanager }} />
                      <Script async src="https://api.adinplay.com/libs/aiptag/pub/NXG/werewolf-online.io/tag.min.js" />
                      <Head>
                        <script id="qc" type="text/javascript" async dangerouslySetInnerHTML={{ __html: qc }} />
                        <script id="aip" type="text/javascript" async dangerouslySetInnerHTML={{ __html: aip }} />
                      </Head>
                      {/* <EzoicAdLoader /> */}
                      <ThemeTranslator>
                        <CssBaseline enableColorScheme />
                        <GA>
                          <Component {...pageProps} err={err} />
                        </GA>
                      </ThemeTranslator>
                    </UnreadMessagesProvider>
                  </FixedChatStateProvider>
                </ChatInputProvider>
              </ChatDataProvider>
            </ChatConnectionProvider>
          </RoomConnectionProvider>
        </Mixpanel>
      </SessionTokenProvider>
    </WerewolfThemeProvider>
  </CacheProvider>
}

function GA(props: { children: ReactElement }) {
  useGoogleAnalyticsAssociateAccountId();

  return props.children;
}

function Mixpanel(props: { children: ReactElement }) {
  useMixpanelUserIdentityAssociator();

  return props.children;
}


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      
// @ts-ignore
    });
// @ts-ignore
  