import { ReactNode, ReactNodeArray, useCallback, useState } from "react";
import { SessionToken } from "../../types/SessionToken";
import { SessionTokenContext } from "./SessionTokenContext";

interface SessionTokenProviderProps {
    value: SessionToken
    children: ReactNode | ReactNodeArray
}

export default function SessionTokenProvider(props: SessionTokenProviderProps) {
    const { value, children } = props;

    const [sessionToken, setSessionToken] = useState(value);

    const updateSessionToken = useCallback((sessionToken: string) => {
        window.localStorage.setItem("sessionToken", sessionToken);
        setSessionToken(sessionToken);
    }, [setSessionToken]);    

    return <SessionTokenContext.Provider value={{ sessionToken, setSessionToken: updateSessionToken }}>
        {children}
    </SessionTokenContext.Provider>
}