import { createTheme, responsiveFontSizes } from "@mui/material";

const lightTheme = responsiveFontSizes(createTheme({
    palette: {
        primary: {
            main: "#00254C"
        },
        secondary: {
            main: "#F15A26"
        },
        background: {
            default: "#EFEFEF"
        }
    },
    typography: {
        fontFamily: [
            'Poppins',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
}));

const darkTheme = responsiveFontSizes(createTheme({
    palette: {
        mode: "dark",
        primary: {
            main: "#00458F"
        },
        secondary: {
            main: "#F15A26"
        },
        background: {
            default: "#1B1E22"
        }
    },
    typography: {
        fontFamily: [
            'Poppins',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
}));

export const themes = {
    light: lightTheme,
    dark: darkTheme
}
