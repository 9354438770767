import mixpanel from "mixpanel-browser";
import { useEffect, useState } from "react";
import useSessionToken from "../hooks/sessiontoken/useSessionToken";
import usePlayer from "../swr/usePlayer";

const key = "ce28cefffe207ae5fa56b608eeb91909";

function check(tcdata, purpose) {
    // Checks in the tcdata object to see if we got consent or were allowed legitimate interest for the purpose
    return tcdata.publisher.consents[purpose] || tcdata.publisher.legitimateInterests[purpose];
}

const dev = process.env.NODE_ENV === "development";

export default function useMixpanel() {
    useEffect(() => {
        mixpanel.init(key, { debug: dev, opt_out_tracking_by_default: !dev });
        mixpanel.track("Mixpanel initialized");
        if (window.__tcfapi !== undefined) {
            window.__tcfapi('addEventListener', 2, function (tcdata, success) {
                if (success) {
                    if (tcdata.gdprApplies === false || ((tcdata.eventStatus === "useractioncomplete" || tcdata.eventStatus === "tcloaded") && check(tcdata, 8) && check(tcdata, 9) && check(tcdata, 10))) {
                        mixpanel.opt_in_tracking();
                        mixpanel.track("Tracking enabled", {
                            gdpr: tcdata.gdprApplies,
                            useraction: tcdata.eventStatus === "useractioncomplete"
                        });
                    }
                } else {
                    console.debug("TCF API didn't return success");
                }

                window.__tcfapi("removeEventListener", 2, function (success) {
                    return null;
                }, tcdata.listenerId);
            });
        } else {
            console.debug("TCF API not defined");
        }
    }, []);
}

export function useMixpanelUserIdentityAssociator() {
    const sessionToken = useSessionToken();
    const player = usePlayer(sessionToken);

    const [id, setId] = useState(undefined);

    useEffect(() => {
        if (player?.player) {
            if (player.player.userId) {
                const userId = player.player.userId;
                if (id !== userId) {
                    mixpanel.track("Account data loaded", {
                        playerId: player.player.id,
                        userId: userId
                    });
                    mixpanel.identify(userId);
                    mixpanel.people.set({ "$name": player.player.username, "$email": player.player.account?.email, "$avatar": player.player.picture?.replace("|", "%7C"), "account": true, "picture": player.player.picture, "one": player.player.one === true, "team": player.player.team === true });
                    setId(userId);
                }
            } else {
                if (id !== player.player.id) {
                    if (mixpanel.get_distinct_id() === id) {
                        mixpanel.track("Logged out");
                        mixpanel.reset();
                    }
                    mixpanel.track("Account data loaded", {
                        playerId: player.player.id,
                        userId: null
                    });
                    mixpanel.identify(player.player.id);
                    mixpanel.people.set({ "$name": player.player.username, "account": false });
                    setId(player.player.id);
                }
            }
        }
    }, [player?.player, setId, id])
}