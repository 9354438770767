import useSWR, { KeyedMutator } from "swr";
import { AutoKickData } from "../types/room/AutoKickData";
import { SessionToken } from "../types/SessionToken";
import fetcher from "../utils/fetcher";

export default function useRoomAutoKick(sessionToken: SessionToken, roomId: string, fallbackData?: AutoKickData): { autoKickData?: AutoKickData, loading: boolean, error: any, mutate: KeyedMutator<any> } {
    const { data, error, mutate } = useSWR(sessionToken && roomId && [(process.env.apiUrl + '/room/'), sessionToken, roomId, "/autokick"], (url, token, roomId, endpoint) => fetcher(url + roomId + endpoint, {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }), { fallbackData });

    return {
        autoKickData: data,
        loading: !error && !data,
        error,
        mutate
    }
}